import React from "react";
import "../about/about.css";
import OfficeImg from "../../assets/gabinet.jpg";

const Services = () => {
    return (
        <section className="about section" id="services">
            <h2 className="section__title">Świadczone usługi</h2>

            <div className="about__container container grid">
                <img src={OfficeImg} alt="" className="about__img" />

                <div className="about__data">

                    <p className="about__description">
                        <b>Konsultacja psychologiczna</b>, to pierwszy kontakt z pacjentem, mający na celu ocenę jego dolegliwości, zdiagnozowanie problemu, a także określenie jego potrzeb.
                    </p>
                    <p className="about__description">
                        <b>Psychoterapia indywidualna</b>, to cykl spotkań ukierunkowanych na leczenie zaburzeń psychicznych, w oparciu o indywidualną konceptualizację pacjenta.
                    </p>
                    <p>
                        Przyjmuję osoby dorosłe zmagające się m.in. z następującymi trudnościami:
                    </p>
                    <ul>
                        <li>
                            Zaburzenia nastroju (w tym depresja)
                        </li>
                        <li>
                            Zaburzenia lękowe (w tym fobie specyficzne, fobia społeczna, zaburzenie obsesyjno-kompulsyjne, zaburzenie lękowe uogólnione itp.)
                        </li>
                        <li>
                            Zaburzenia odżywiania
                        </li>
                        <li>
                            Zaburzenia osobowości
                        </li>
                        <li>
                            Kryzysy
                        </li>
                        <li>
                            Niskie poczucie własnej wartości
                        </li>
                    </ul>
                    <p>
                        *Każda sesja trwa 50 min.
                    </p>
                    <p>
                        *Jeżeli z jakichś względów nie możesz się ze mną spotkać w gabinecie, możemy spotkać się online.
                    </p>

                </div>
            </div>
        </section>
    )
}

export default Services;