import './App.css';
import Header from './components/header/Header';
import Home from './components/home/Home';
import About from './components/about/About'
import Services from './components/services/Services';
import Certificates from './components/certificates/Certificates';
import Prices from './components/prices/Prices';
import Contact from './components/contact/Contact';

function App() {
  return (
    <>
      <Header />

      <main className='main'>
        <Home />
        <About />
        <Services />
        {/* <Certificates /> */}
        <Prices />
        <Contact />
      </main>
    </>
  )
}

export default App;
