import React from "react";
import "./about.css";
import AboutImg from "../../assets/profile2.jpg";
import Info from "./Info";

const About = () => {
    return (
        <section className="about section" id="about">
            <h2 className="section__title">O mnie</h2>
            <span className="section__subtitle">Pare słów o mnie.</span>

            <div className="about__container container grid">
                <img src={AboutImg} alt="" className="about__img" />

                <div className="about__data">
                    {/* <Info /> */}

                    <p className="about__description">
                        Jestem psychologiem, diagnostą i psychoterapeutą poznawczo-behawioralnym. Jestem także członkiem Polskiego Towarzystwa Terapii Poznawczej i Behawioralnej.
                    </p>
                    <p className="about__description">
                        Posiadam wieloletnie doświadczenie w pracy z dziećmi, młodzieżą oraz osobami dorosłymi. Aktualnie zajmuję się pacjentami dorosłymi. Swoją pracę opieram na technikach nurtu poznawczo-behawioralnego, z włączeniem elementów terapii schematów. Skupiam dużą uwagę na to, by każdy, kto zdecyduje się powierzyć mi swoje trudności, czuł się bezpiecznie, na właściwym miejscu. Nieustannie podnoszę swoje kwalifikacje, a pracę poddaję regularnej superwizji.
                    </p>


                </div>
            </div>
        </section>
    )
}

export default About;