import React from "react";
import "../about/about.css";

const Contact = () => {
    return (
        <section className="about section" id="contact">
            <h2 className="section__title">Kontakt</h2>

            <div className="about__container container grid">
                <div>
                    <p>
                        Tel. 791 577 391 <br />

                    </p>
                    <br />
                    <p>
                        ul. Księdza Konstantego Damrota 7 pok. 10 - piętro I <br />
                        45-064 Opole
                    </p>
                    {/* <br />
                    <p>
                        Na miejsce dotrzesz autobusami MZK z Dworca Głównego (przystanek przy Centrum Przesiadkowym), wysiadając na przystanku 1MAJA – PLEBISCYTOWA.
                    </p>
                    <p>
                        Linie nr 3, 5, 9, 11, 13, 16
                    </p> */}
                </div>

                <div className="about__data">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2528.9562266741605!2d17.92657087730038!3d50.665073271886286!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4710530f77e99dc9%3A0x6540b0dcb23c733a!2sKsi%C4%99dza%20Konstantego%20Damrota%207%2C%2046-020%20Opole!5e0!3m2!1spl!2spl!4v1702628155436!5m2!1spl!2spl" width="600" height="450" style={{ border: 0 }} loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
        </section>
    )
}

export default Contact;