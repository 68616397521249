import React from "react";
import "../about/about.css";
import Money from "../../assets/money.jpg";

const Prices = () => {
    return (
        <section className="about section" id="prices">
            <h2 className="section__title">Cennik</h2>

            <div className="about__container container grid">
                <img src={Money} alt="" className="about__img" />

                <div className="about__data">
                    <ul>
                        <li>
                            Konsultacja psychologiczna - 200zł (50 min)
                        </li>
                        <li>
                            Psychoterapia indywidualna - 200zł (50 min)
                        </li>
                        <li>
                            Konsultacja psychologiczna online - 200zł (50 min)
                        </li>
                        <li>
                            Psychoterapia indywidualna online - 200zł (50 min)
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    )
}

export default Prices;