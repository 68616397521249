import React from "react";

const Social = () => {
    return (
        <div className="home__social">
            <a href="https://www.facebook.com/profile.php?id=100082854188671" className="home__social-icon" target="_blank" rel="noreferrer">
            <i className="uil uil-facebook-f"></i>
            </a>
            <a href="https://m.me/profile.php?id=100082854188671" className="home__social-icon" target="_blank" rel="noreferrer">
                <i className="uil uil-facebook-messenger-alt"></i>
            </a>
            <a href="https://wa.me/48791577391" className="home__social-icon" target="_blank" rel="noreferrer">
                <i className="uil uil-whatsapp"></i>
            </a>

            <a href="https://www.znanylekarz.pl/danuta-jordan/psycholog/opole" className="home__social-icon" target="_blank" rel="noreferrer">
                {/* <i className="uil uil-medical-square-full"></i> */}
            <img className="znany-lekarz" src={require("./znanylekarz.png")} alt=""></img>
            </a>
        </div>
    )

}

export default Social;